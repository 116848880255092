import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import blogData from '../assets/fake-data/data-blog'
import HeaderBlog from '../components/header/HeaderBlog';
import Footer from '../components/footer/Footer';
import { client } from "../lib/sanityClient";

import BlockContent from '@sanity/block-content-to-react';
import Moment from 'react-moment';
import 'moment-timezone';



const Blog = () => {
    const [data] = useState(blogData);

    const [blog, setBlog] = useState([]);
    useEffect(() => {
        fetchCollectionData();
    }, []);

    const fetchCollectionData = async (sanityClient = client) => {
        const query = `*[_type == "post" ] | order(publishedAt){
            title,
       slug,
       "writer": author->name,
   "writerImage": author->image.asset->url,
        "galleryURL": mainImage.asset->url,
       categories,
       publishedAt,
       
     }`;

        const collectionData = await sanityClient.fetch(query);


        // the query returns 1 object inside of an array
        await setBlog(collectionData);
    };




    const [visible, setVisible] = useState(6);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 3);
    }

	
	

    return (
        <div>
            <HeaderBlog />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Blog</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Community</Link></li>
                                    <li>Blog</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-section sc-card-blog dark-style2">
                <div className="themesflat-container">
                    <div className="row">
                        {
                            blog.slice(0, visible).map((item, index) => (
                                <BlogItem key={index} item={item} />
                            ))
                        }
                        {
                            visible < blog.length &&
                            <div className="col-md-12 wrap-inner load-more text-center">
                                <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3 mt-6" onClick={showMoreItems}><span>Load More</span></Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

const BlogItem = props => (
    <div className="fl-blog fl-item2 col-lg-4 col-md-6">
        <article className="sc-card-article">
            <div className="card-media">
                {/* 395px x 275px */}
                <Link to={`/blog-details/${props.item.slug.current}`}><img src={`${props.item.galleryURL}?w=395`} alt="Axies" /></Link>
            </div>
            <div className="meta-info">
                             <div className="author">
                     <div className="avatar">
                        <img src={props.item.writerImage} alt="author" />
                    </div> 
                    <div className="info">
                        <span>Writer</span>
                        <h6> {props.item.writer} </h6>
                    </div>
                </div>
                <div className="date"><Moment  tz="Asia/Hong_Kong" locale="en" format="YYYY/MM/DD hh:mm a" date={props.item.publishedAt} /></div>
            </div>
            <div className="text-article">
                <h3><Link to={`/blog-details/${props.item.slug.current}`} >{props.item.title}</Link></h3>
                <p>{props.item.content}</p>
            </div>
            <Link to={`/blog-details/${props.item.slug.current}`} className="sc-button fl-button pri-3"><span>Read More</span></Link>
        </article>
    </div>
)

export default Blog;
