import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Accordion } from 'react-bootstrap-accordion'
import Parser from 'html-react-parser';
import { client } from "../lib/sanityClient";
import BlockContent from '@sanity/block-content-to-react';


const FAQComponent = () => {
    const [faq, setFaq] = useState([]);
    useEffect(() => {
        fetchCollectionData();
    }, []);
  
    const fetchCollectionData = async (sanityClient = client) => {
        const query = `*[_type == "faq"  ]| order(priority asc) {
            "key":priority,
            title,
            "text":description,
            priority
            }`;
  
        const collectionData = await sanityClient.fetch(query);
  
  
        // the query returns 1 object inside of an array
        await setFaq(collectionData);
    };


    // const [data] = useState(
    //     [
    //         {   key: "0",
    //             show: "show",
    //             title: 'What is Bossy Lion 🦁 ?',
    //             text: `Bossy Lion is an exclusive NFT community, inspired by Hong Kong's local traditions and culture. Embrace the "Lion" spirit and our bossy personalities!`
    //         },
    //         {
    //             key: "1",
    //             title: 'How can I buy?',
    //             text: `You will be able to mint Bossy Lion🦁  directly on our website. Link to be posted in Discrod 📣｜announcement `
    //         },
    //         {
    //             key: "2",
    //             title: 'What Solana wallets can I use?',
    //             text: 'All of Solana’s main wallets, including Phantom, Sollet and Solflare'
    //         },
    //         {
    //             key: "3",
    //             title: 'How many Lions are available?',
    //             text: 'There are a total of 5,852 Bossy Lions roaring at you'
    //         },
    //     ]
    // )

    if( faq === null){
        return "";
    }

    return (
        <div id="faq">
            <section className="tf-section wrap-accordion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">
                                Frequently Asked Questions
                            </h2>
                            <h5 className="sub-title help-center mg-bt-32 ">
                            
                            </h5>
                        </div>
                        <div className="col-md-12">
                            <div className="flat-accordion2">
                                {
                                    faq.map((item,index) => (
                                        <Accordion key={index} title={item.title} >
                                            <p>{Parser(item.text)}</p>
                                        </Accordion>
                                    ))
                                }                             
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default FAQComponent;
