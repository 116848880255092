import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { client } from "../../../lib/sanityClient";

const TopSeller = props => {
    const data = props.data;

    
    const [teammate, setTeammate] = useState([]);
    useEffect(() => {
        fetchCollectionData();
    }, []);

    const fetchCollectionData = async (sanityClient = client) => {
        const query = `*[_type == "teammate"  ]| order(priority) {
            title,
          description,
          "galleryURL": image.asset->url,
          }`;

        const collectionData = await sanityClient.fetch(query);


        // the query returns 1 object inside of an array
        await setTeammate(collectionData);
    };


    if(teammate===null){
        return "";
    }

    return (
        <section className="tf-section top-seller bg-home-3" id="ourteam">
            <br/> <br/> <br/> <br/> <br/> <br/>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="">
                            <h2 className="tf-title style2">
                                Our Team</h2>
                            <div className="heading-line s1"></div>
                        </div>
                    </div>
                    {
                        teammate.map((item,index) => (
                            <TopSellerItem key={index} item={item} />
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

const TopSellerItem = props => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
        <div className="sc-author-box">
            <div className="author-avatar">
                <Link to="#">
                    <img src={props.item.galleryURL} alt="Bossy Lion NFT" className="avatar" />
                </Link>
                <div className="badge"><i className="ripple"></i></div>
            </div>
            <div className="author-infor">
                <h5 className="style2"><Link to="#">{props.item.title}</Link></h5>
                <span className="price">{props.item.description}</span>
            </div>
        </div>    
    </div>
)

export default TopSeller;
