
import React, { useState, useEffect,Fragment } from 'react';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import Countdown from "react-countdown";
import CardModal from "../CardModal";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import img1 from "../../../assets/images/bossylions/bossy-lion-1.png";
import img2 from "../../../assets/images/bossylions/bossy-lion-2.png";
import img3 from "../../../assets/images/bossylions/bossy-lion-3.png";
import img4 from "../../../assets/images/bossylions/bossy-lion-4.png";
import img5 from "../../../assets/images/bossylions/bossy-lion-5.png";
import img6 from "../../../assets/images/bossylions/bossy-lion-6.png";
import img7 from "../../../assets/images/bossylions/bossy-lion-7.png";
import img8 from "../../../assets/images/bossylions/bossy-lion-8.png";
import img9 from "../../../assets/images/bossylions/bossy-lion-9.png";
import img10 from "../../../assets/images/bossylions/bossy-lion-10.png";
import img11 from "../../../assets/images/bossylions/bossy-lion-11.png";
import img12 from "../../../assets/images/bossylions/bossy-lion-12.png";
import img13 from "../../../assets/images/bossylions/bossy-lion-13.png";
import img14 from "../../../assets/images/bossylions/bossy-lion-14.png";
import img15 from "../../../assets/images/bossylions/bossy-lion-15.png";
import img16 from "../../../assets/images/bossylions/bossy-lion-16.png";
import img17 from "../../../assets/images/bossylions/bossy-lion-17.png";
import img18 from "../../../assets/images/bossylions/bossy-lion-18.png";
import img19 from "../../../assets/images/bossylions/bossy-lion-19.png";
import img20 from "../../../assets/images/bossylions/bossy-lion-20.png";
import img21 from "../../../assets/images/bossylions/bossy-lion-21.png";
import img22 from "../../../assets/images/bossylions/bossy-lion-22.png";
import img23 from "../../../assets/images/bossylions/bossy-lion-23.png";
import img24 from "../../../assets/images/bossylions/bossy-lion-24.png";

import imga1 from "../../../assets/images/avatar/avt-11.jpg";
import imga2 from "../../../assets/images/avatar/avt-12.jpg";
import imga3 from "../../../assets/images/avatar/avt-13.jpg";

import { client } from "../../../lib/sanityClient";




const LiveAuction = () => {


  const [gallery, setGallery] = useState(null);
  useEffect(() => {
      fetchCollectionData();
  }, []);

  const fetchCollectionData = async (sanityClient = client) => {
      const query = `*[_type == "gallery"  ] {
        "galleryURL": images[].asset->url,
  }`;

      const collectionData = await sanityClient.fetch(query);


      // the query returns 1 object inside of an array
      await setGallery(collectionData[0].galleryURL);
  };




  const [modalShow, setModalShow] = useState(false);

  if(gallery === null){
    return "";
  }

  return (
    <Fragment>
      <section className="tf-section live-auctions">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-live-auctions">
                <h2 className="tf-title pb-20" id="gallery">
                  Gallery
                </h2>
              </div>
            </div>
            <div className="col-md-12">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={30}
                breakpoints={{
                  0: {
                    slidesPerView: 3,
                  },
                  767: {
                    slidesPerView: 2,
                  },
                  991: {
                    slidesPerView: 5,
                  },
                }}
                navigation={false}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
              >
                {gallery.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="swiper-container show-shadow carousel auctions">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div className="slider-item">
                            <div className="sc-card-product">
                              <div className="card-media">
                                <img src={`${item}?w=222`} alt="Bossy Lion NFT" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </Fragment>
  );
};

export default LiveAuction;
