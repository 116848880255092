import "./App.css";
import { Routes, Route } from "react-router-dom";
import routes from "./pages/index";

import { Connection, programs } from "@metaplex/js";

function App() {
  const {
    metadata: { Metadata },
  } = programs;

  const connection = new Connection("devnet");
  const tokenPublicKey = "2h3NkXkcphqA5PGY3E1ki15PuzcDZfzQYQmXaU9kJEAC";


  const run = async () => {
    try {
      const ownedMetadata = await Metadata.load(connection, tokenPublicKey);
      console.log(ownedMetadata);
    } catch {
      console.log("Failed to fetch metadata");
    }
  };

  run();
  return (
    <Routes>
      {routes.map((data, index) => (
        <Route
          onUpdate={() => window.scrollTo(0, 0)}
          exact={true}
          path={data.path}
          element={data.component}
          key={index}
        />
      ))}
    </Routes>
  );
}

export default App;
