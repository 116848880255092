import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Accordion } from "react-bootstrap-accordion";
import { client } from "../lib/sanityClient";
import BlockContent from '@sanity/block-content-to-react';


const BlockRenderer = (props) => {
  const {style = 'normal'} = props.node


  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}



const NewRoadmapComponent = (props) => {

  const data = props.data;

    
  const [roadmap, setRoadmap] = useState([]);
  useEffect(() => {
      fetchCollectionData();
  }, []);

  const fetchCollectionData = async (sanityClient = client) => {
      const query = `*[_type == "roadmap"  ]| order(priority) {
        title,
      description
      }`;

      const collectionData = await sanityClient.fetch(query);


      // the query returns 1 object inside of an array
      await setRoadmap(collectionData);
  };



  if(roadmap===null){
    return "";
}
  return (
    <div id="roadmap">
      <section
        className="tf-section wrap-accordion"
        style={{
          background:
            "linear-gradient(181deg, rgb(141, 32, 140) 10%, rgb(2, 37, 184) 82.84%)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2
                className="tf-title-heading ct style-2 fs-30 mg-bt-10"
                style={{ color: "white" }}
              >
                Roadmap and Proposition
              </h2>
              <h5
                className="sub-title help-center mg-bt-32 "
                style={{ color: "white" }}
              >
                Here is our roadmap to launch our platform
              </h5>
            </div>
            <div className="col-md-12">
              <div className="timeline">

              {
                  roadmap.map((item,index) => (
                    
                          <div className={`tl-container ${index%2===0 ? "tl-right" : "tl-left"}`}> 
                          <div className="tl-content">
                            <h5 style={{ marginBottom: 20 }}>{item.title}</h5>

                            <p>

                            <BlockContent projectId={'zs67alnl'} dataset={'production'} blocks={item.description} serializers={{types: {block: BlockRenderer}}}  />


                            </p>
                          </div>
                        </div>
                
                  ))
             }









              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </div>
  );
};

export default NewRoadmapComponent;
