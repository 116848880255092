import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import HeaderBlog from '../components/header/HeaderBlog';
import Footer from '../components/footer/Footer';
import { useParams } from "react-router-dom";

import img1 from '../assets/images/box-item/icon1-recont-post.jpg'
import img2 from '../assets/images/box-item/icon2-recont-post.jpg'
import img3 from '../assets/images/box-item/icon3-recont-post.jpg'
import img4 from '../assets/images/box-item/icon4-recont-post.jpg'
import imgblog1 from '../assets/images/blog/thumb-7.jpg'
import imgblog2 from '../assets/images/blog/thumb-6.jpg'
import imgblogdetail1 from '../assets/images/blog/thumb1_details.jpg'
import imgblogdetail2 from '../assets/images/blog/thumb2_details.jpg'
import { client } from "../lib/sanityClient";
import BlockContent  from '@sanity/block-content-to-react';
import Moment from 'react-moment';
import 'moment-timezone';

const serializers = {
    types: {
      break: props => {
        const { style } = props.node;
        if (style === "lineBreak") {
          return <hr className="lineBreak" />;
        }
 
        return null;
      },
    }
  }
const BlogDetails = () => {
    const { slug } = useParams();

    const [blogRecord, setBlogRecord] = useState(null);
    useEffect(() => {
        fetchCollectionData();
    }, []);

    const fetchCollectionData = async (sanityClient = client) => {
        const query = `*[_type == "post" && slug.current == "${slug}" ] {
            "category": categories[0]->title,

       title,
  slug,
  "writer": author->name,
   "galleryURL": mainImage.asset->url,
  categories,
  publishedAt,
  body
}`;

        const collectionData = await sanityClient.fetch(query);


        // the query returns 1 object inside of an array
        await setBlogRecord(collectionData[0]);
    };

   

	
    if(blogRecord === null){
        return "";
    }
	
    return (
        <div>
            <HeaderBlog />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Blog Details</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Community</Link></li>
                                    <li>Blog Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tf-section post-details">
                <div className="themesflat-container">
                    <div className="wrap-flex-box style">
                        <div className="post">
                            <div className="inner-content">
                                <h2 className="title-post">{blogRecord.title}</h2>
                                <div className="divider"></div>
                                <div className="meta-post flex mg-bt-31">
                                    <div className="box">
                                        <div className="inner">
                                            <h6 className="desc">CATEGORY</h6>
                                            <p>{blogRecord.category}</p>
                                        </div>
                                    </div>
                                    <div className="box left">
                                        <div className="inner boder pad-r-50">
                                            <h6 className="desc">WRITER</h6>
                                            <p>{blogRecord.writer}</p>
                                        </div>
                                        <div className="inner mg-l-39 mg-r-1">
                                            <h6 className="desc">DATE</h6>
                                            <p><Moment  tz="Asia/Hong_Kong" locale="en" format="YYYY/MM/DD hh:mm a" date={blogRecord.publishedAt} /></p>
                                        </div>
                                    </div>
                                </div>
                           
                                <div className="inner-post mg-t-40">


     <BlockContent projectId={'zs67alnl'} dataset={'production'} blocks={blogRecord.body} serializers={serializers}  />
 






                         
                                </div>
                          
                              
                    
                            </div>
                        </div>
                 
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default BlogDetails;
