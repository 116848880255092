import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import img1 from "../../assets/images/bossylions/bossy-lion-1.png";
import img2 from "../../assets/images/bossylions/bossy-lion-2.png";
import img3 from "../../assets/images/bossylions/bossy-lion-3.png";
import img4 from "../../assets/images/bossylions/bossy-lion-4.png";
import img5 from "../../assets/images/bossylions/bossy-lion-5.png";
import img6 from "../../assets/images/bossylions/bossy-lion-6.png";
import img7 from "../../assets/images/bossylions/bossy-lion-7.png";
import img8 from "../../assets/images/bossylions/bossy-lion-8.png";
import img9 from "../../assets/images/bossylions/bossy-lion-9.png";
import img10 from "../../assets/images/bossylions/bossy-lion-10.png";
import img11 from "../../assets/images/bossylions/bossy-lion-11.png";
import img12 from "../../assets/images/bossylions/bossy-lion-12.png";
import img13 from "../../assets/images/bossylions/bossy-lion-13.png";
import img14 from "../../assets/images/bossylions/bossy-lion-14.png";
import img15 from "../../assets/images/bossylions/bossy-lion-15.png";
import img16 from "../../assets/images/bossylions/bossy-lion-16.png";
import img17 from "../../assets/images/bossylions/bossy-lion-17.png";
import img18 from "../../assets/images/bossylions/bossy-lion-18.png";
import img19 from "../../assets/images/bossylions/bossy-lion-19.png";
import img20 from "../../assets/images/bossylions/bossy-lion-20.png";
import img21 from "../../assets/images/bossylions/bossy-lion-21.png";
import img22 from "../../assets/images/bossylions/bossy-lion-22.png";
import img23 from "../../assets/images/bossylions/bossy-lion-23.png";
import img24 from "../../assets/images/bossylions/bossy-lion-24.png";





import { FaInstagram } from "react-icons/fa";


const SliderStyle2 = () => {
  const subtitle = "BOSSY LION";
  const title = "COLLECTION OF 5,852 BOSSY LION WAITING TO BE ADOPTED";
  const description =
    "Algorithmically generated talisman of prosperity and fortune, living on the Solana Blockchain.";
  return (
    <section className="flat-title-page home5"  id="home">
      <div className="overlay"></div>
      <div className="themesflat-container moving-background">
        <div className="wrap-heading flat-slider d-flex align-items-center">
          <div className="content">
            <h4 className="mg-bt-11">
              <span className="fill">{subtitle}</span>
            </h4>
            <h1 className="heading">{title}</h1>
            <p className="sub-heading mg-t-7 mg-bt-39">{description}</p>
            <div className="flat-bt-slider style2 flex">
              <a
                href="https://linktr.ee/BossyLions"
                target="_blank"
                className="sc-button header-slider style style-1 note fl-button pri-1" rel="noreferrer"
              >
                <span><i className="icon-fl-vt"></i> Discord</span>
              </a>
    

              <a
                href="https://www.instagram.com/bossy.lion/"
                target="_blank"
                className="sc-button header-slider style style-1 note fl-button pri-1" rel="noreferrer"
              >
                <span><i className="fab fa-instagram"></i> Instagram</span>
              </a>
            </div>
          </div>

          <Swiper
            modules={[Autoplay]}
            direction={"vertical"}
            spaceBetween={10}
            slidesPerView={5}
            loop
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={2000}
          >
            <SwiperSlide>
              <img src={img1} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img2} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img3} alt="Bossy Lion NFT" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={img4} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img5} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img6} alt="Bossy Lion NFT" />
            </SwiperSlide>


  


          </Swiper>
          <Swiper
            modules={[Autoplay]}
            direction={"vertical"}
            spaceBetween={10}
            slidesPerView={5}
            loop
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={2100}
          >

            <SwiperSlide>
              <img src={img7} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img8} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img9} alt="Bossy Lion NFT" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={img10} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img11} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img12} alt="Bossy Lion NFT" />
            </SwiperSlide>




          </Swiper>
          <Swiper
            modules={[Autoplay]}
            direction={"vertical"}
            spaceBetween={10}
            slidesPerView={5}
            loop
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={2200}
          >
               <SwiperSlide>
              <img src={img13} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img14} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img15} alt="Bossy Lion NFT" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={img16} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img17} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img18} alt="Bossy Lion NFT" />
            </SwiperSlide>



          </Swiper>
          <Swiper
            modules={[Autoplay]}
            direction={"vertical"}
            spaceBetween={10}
            slidesPerView={5}
            loop
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={2000}
            className="end"
          >
               <SwiperSlide>
              <img src={img19} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img20} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img21} alt="Bossy Lion NFT" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={img22} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img23} alt="Bossy Lion NFT" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img24} alt="Bossy Lion NFT" />
            </SwiperSlide>



          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default SliderStyle2;
