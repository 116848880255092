const menus = [
    {
        id: 1,
        name: 'Home',
        links: 'home'
    },
    {
        id: 2,
        name: 'Gallery',
        links: 'gallery'
    },
    {
        id: 3,
        name: 'Features',
        links: 'features'
    },
    {
        id: 4,
        name: 'Roadmap',
        links: 'roadmap'
    },
    {
        id: 5,
        name: 'Our Team',
        links: 'ourteam'
    },
    {
        id: 6,
        name: 'FAQ',
        links: 'faq'
    },
    {
        id: 7,
        name: 'Community',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Blog',
                links: '/blog'
            },
        ],
    },
]

export default menus;