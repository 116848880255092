import React, { useState, useEffect } from "react";


import Header from '../components/header/Header';

import HeaderStyle2 from '../components/header/HeaderStyle2';
import Footer from '../components/footer/Footer';
import SliderStyle2 from '../components/slider/SliderStyle2';
import heroSliderData from '../assets/fake-data/data-slider';
import BrowCategory from '../components/layouts/home-5/BrowCategory';
import LiveAuction from '../components/layouts/home-5/LiveAuction';

import Featuers from '../components/layouts/home-5/Featuers';

import { Link } from 'react-router-dom';


import TopSeller from '../components/layouts/home-5/TopSeller';
import TodayPicks from '../components/layouts/home-5/TodayPicks';
import todayPickData from '../assets/fake-data/data-today-pick';
import PopularCollection from '../components/layouts/home-5/PopularCollection';
import Create from '../components/layouts/home-2/Create';
import FAQComponent from '../pages/FAQComponent';
import NewRoadmapComponent from '../pages/NewRoadmapComponent';

import widgetSidebarData from '../assets/fake-data/data-widget-sidebar'
import Explore from '../components/layouts/explore-04/Explore';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import ourTeamData from '../assets/fake-data/data-our-team';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

import { client } from "../lib/sanityClient";



const Home05 = () => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [homeBanner, setHomeBanner] = useState(null);
  useEffect(() => {
    fetchCollectionData();
  }, []);

  const fetchCollectionData = async (sanityClient = client) => {
    const query = `*[_type == "homebanner"  ] {
        "title":post[0]->title,
       "slug":post[0]->slug.current,
        "galleryURL": post[0]->mainImage.asset->url,
     }`;

    const collectionData = await sanityClient.fetch(query);


    // the query returns 1 object inside of an array
    await setHomeBanner(collectionData[0]);
  };

  if (homeBanner === null) {
    return "";
  }


  return (
    <div className='home-5'>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><h3>{homeBanner.title}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign:'center'}}>

          <Link to={`/blog-details/${homeBanner.slug}`}><img src={`${homeBanner.galleryURL}?w=400`} alt="Axies" style={{ width: '80%' }} /></Link>


        </Modal.Body>
        <Modal.Footer>
        {/* <Link to={`/blog-details/${homeBanner.slug}`}><Button>See Detail</Button></Link> */}
        </Modal.Footer>
      </Modal>



      <Header />
      <SliderStyle2 data={heroSliderData} />
      <LiveAuction data={liveAuctionData} />
      <Featuers data={liveAuctionData} />

      <NewRoadmapComponent />
      {/* <Create /> */}
      <TopSeller />

      <FAQComponent />

      <Footer />
    </div>
  );
}

export default Home05;
