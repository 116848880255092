import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";


const Featuers = () => {
  const [selectedCategory, setSelectedCategory] = useState("background");

  const [dataTopSellerTab] = useState([
    {
      title: "1 Day",
    },
    {
      title: "1 Week",
    },
    {
      title: "1 Month",
    },
  ]);
  const [featureCollection] = useState([
    {
      id: 1,
      category: "background",
      itemList: [
        {
          img: "../../../assets/images/featuers/Background/background_hk_orange.jpg",
          trait: "hk Orange",
          occurrence: "1.39%",
        },
        {
          img: "../../../assets/images/featuers/Background/background_hk_pink.jpg",
          trait: "hk Pink",
          occurrence: "1.37%",
        },
        {
          img: "../../../assets/images/featuers/Background/background_iceblue.jpg",
          trait: "iceblue",
          occurrence: "1.41%",
        },
        {
          img: "../../../assets/images/featuers/Background/background_night.jpg",
          trait: "night",
          occurrence: "1.36%",
        },
        {
          img: "../../../assets/images/featuers/Background/background_peach.jpg",
          trait: "peach",
          occurrence: "1.32%",
        },
        {
          img: "../../../assets/images/featuers/Background/background_purple.jpg",
          trait: "purple",
          occurrence: "1.39%",
        },
      ],
    },
    {
      id: 2,
      category: "fur",
      itemList: [
        {
          img: "../../../assets/images/featuers/Fur/fur_blackpink.jpg",
          trait: "blackpink",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Fur/fur_frog.jpg",
          trait: "frog",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Fur/fur_grey.jpg",
          trait: "grey",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Fur/fur_orange.jpg",
          trait: "orange",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Fur/fur_purple.jpg",
          trait: "purple",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Fur/fur_white.jpg",
          trait: "white",
          occurrence: "2%",
        },
      ],
    },
    {
      id: 3,
      category: "eyes",
      itemList: [
        {
          img: "../../../assets/images/featuers/Eyes/01_eye_bossy.jpg",
          trait: "bossy",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Eyes/eye_angry.jpg",
          trait: "angry",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Eyes/eye_glasses.jpg",
          trait: "glasses",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Eyes/eye_glasses_black.jpg",
          trait: "glasses_black",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Eyes/eye_qq.jpg",
          trait: "qq",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Eyes/eye_troll_black.jpg",
          trait: "troll black",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Eyes/eye_wicked.jpg",
          trait: "wicked",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Eyes/eye_wink.jpg",
          trait: "wink",
          occurrence: "2%",
        },
      ],
    },
    {
      id: 4,
      category: "outfit",
      itemList: [
        {
          img: "../../../assets/images/featuers/Outfit/outfit_baseballjacket_blue.jpg",
          trait: "baseball jacket blue",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_baseballjacket_green.jpg",
          trait: "baseball jacket green",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_baseballjacket_grey.jpg",
          trait: "baseball jacket grey",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_baseballjacket_red.jpg",
          trait: "baseball jacket red",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_black_hoodie.jpg",
          trait: "black hoodie",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_hoodie_blue.jpg",
          trait: "hoodie blue",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_hoodie_pink.jpg",
          trait: "hoodie pink",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_jacket_neon.jpg",
          trait: "jacket neon",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_jacket_orange.jpg",
          trait: "jacket orange",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_overall_blue.jpg",
          trait: "overall blue",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_overall_red.jpg",
          trait: "overall red",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_soccer_barcelona.jpg",
          trait: "soccer barcelona",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_tee_bossy.jpg",
          trait: "bossy",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_tee_nike_black.jpg",
          trait: "Nike black",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_tee_nike_white.jpg",
          trait: "nike white",
          occurrence: "2%",
        },
        {
          img: "../../../assets/images/featuers/Outfit/outfit_tie_red.jpg",
          trait: "tie red",
          occurrence: "2%",
        },
      ],
    },
    {
      id: 5,
      category: "mouth",
      itemList: [
        {
          img: "../../../assets/images/featuers/Mouth/mouth_cat.jpg",
          trait: "cat",
          occurrence: "0.03%",
        },
        {
          img: "../../../assets/images/featuers/Mouth/mouth_gasmask.jpg",
          trait: "gasmask",
          occurrence: "0.12%",
        },
        {
          img: "../../../assets/images/featuers/Mouth/mouth_maskon_breezy.jpg",
          trait: "maskon breezy",
          occurrence: "0.37%",
        },
        {
          img: "../../../assets/images/featuers/Mouth/mouth_maskon_dainty.jpg",
          trait: "maskon dainty",
          occurrence: "0.37%",
        },
        {
          img: "../../../assets/images/featuers/Mouth/mouth_maskon_serene.jpg",
          trait: "maskon serene",
          occurrence: "0.21%",
        },
        {
          img: "../../../assets/images/featuers/Mouth/mouth_maskon_wicked.jpg",
          trait: "maskon wicked",
          occurrence: "0.17%",
        },
        {
          img: "../../../assets/images/featuers/Mouth/mouth_smoke.jpg",
          trait: "smoke",
          occurrence: "0.01%",
        },
        {
          img: "../../../assets/images/featuers/Mouth/mouth_vampire.jpg",
          trait: "vampire",
          occurrence: "0.01%",
        },
      ],
    },
    {
      id: 6,
      category: "headwear",
      itemList: [
        {
          img: "../../../assets/images/featuers/Headwear/head_beanie_gucci.jpg",
          trait: "beanie gucci",
          occurrence: "0.46%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_beanie_supreme.jpg",
          trait: "beanie supreme",
          occurrence: "0.46%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_bucket_prada.jpg",
          trait: "bucket prada",
          occurrence: "0.46%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_hagao.jpg",
          trait: "hagao",
          occurrence: "0.39%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_monkeyking.jpg",
          trait: "monkeyking",
          occurrence: "0.39%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_murakami_blue.jpg",
          trait: "murakami blue",
          occurrence: "0.39%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_murakami_pink.jpg",
          trait: "murakami pink",
          occurrence: "0.30%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_murakami_rainbow.jpg",
          trait: "murakami rainbow",
          occurrence: "0.35%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_murakami_yellow.jpg",
          trait: "murakami_yellow",
          occurrence: "0.26%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_nike4.jpg",
          trait: "nike4",
          occurrence: "0.3%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_pineapplebun.jpg",
          trait: "pineapplebun",
          occurrence: "0.34%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_shiba_black.jpg",
          trait: "shiba black",
          occurrence: "0.41%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_shiba_brown.jpg",
          trait: "shiba brown",
          occurrence: "0.44%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_siulungbao.jpg",
          trait: "siulungbao",
          occurrence: "0.32%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_siumai.jpg",
          trait: "siumai",
          occurrence: "0.41%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_snagzinbao.jpg",
          trait: "snagzinbao",
          occurrence: "0.41%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_sushi1.jpg",
          trait: "sushi1",
          occurrence: "0.19%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_sushi2.jpg",
          trait: "sushi2",
          occurrence: "0.28%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_sushi3.jpg",
          trait: "sushi3",
          occurrence: "0.48%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_sushi5.jpg",
          trait: "sushi5",
          occurrence: "0.32%",
        },
        {
          img: "../../../assets/images/featuers/Headwear/head_unicorn.jpg",
          trait: "unicorn",
          occurrence: "0.23%",
        },
      ]
    },
    {
      id: 7,
      category: "hand",
      itemList: [
        {
          img: "../../../assets/images/featuers/Hand/hand_VLT.jpg",
          trait: "VLT",
          occurrence: "0.6%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_bball.jpg",
          trait: "bball",
          occurrence: "0.73%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_beer.jpg",
          trait: "beer",
          occurrence: "0.91%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_bubbletea.jpg",
          trait: "bubble tea",
          occurrence: "0.8%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_coke.jpg",
          trait: "coke",
          occurrence: "0.85%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_dumbbell.jpg",
          trait: "dumb bell",
          occurrence: "0.64%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_eggtart.jpg",
          trait: "egg Tart",
          occurrence: "0.77%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_fishball.jpg",
          trait: "fishball",
          occurrence: "0.75%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_haziyuen.jpg",
          trait: "haziyuen",
          occurrence: "0.64%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_makyuyuen.jpg",
          trait: "makyuyuen",
          occurrence: "0.59%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_mic.jpg",
          trait: "mic",
          occurrence: "0.71%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_milktea.jpg",
          trait: "milk tea",
          occurrence: "0.93%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_mj_bak.jpg",
          trait: "Mahjong bak",
          occurrence: "0.91%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_mj_chung.jpg",
          trait: "Mahjong chung",
          occurrence: "0.89%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_mj_fat.jpg",
          trait: "Mahjong fat",
          occurrence: "0.73%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_pocari.jpg",
          trait: "Pocari",
          occurrence: "0.91%",
        },
        {
          img: "../../../assets/images/featuers/Hand/hand_potart.jpg",
          trait: "Po tart",
          occurrence: "0.89%",
        },
      ]
    }
  ]);
  return (
    <div id="features">
      <section className="tf-section top-seller home5 s2 " style={{    background: 'aliceblue'}}>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <h2 className="tf-title style2 mb-25 text-left">Features</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-2 col-lg-2">
              <button
                onClick={() => setSelectedCategory("background")}
                className={`sc-button btn-feature-width  fl-button pri-3  mb-2 ${
                  selectedCategory === "background" ? "" : "no-bg text-white"
                }`}
              >
                <span
                  style={{
                    color: selectedCategory === "background" ? "white" : "blue",
                  }}
                >
                  Background
                </span>
              </button>
              <button
                onClick={() => setSelectedCategory("fur")}
                className={`sc-button btn-feature-width  fl-button pri-3  mb-2 ${
                  selectedCategory === "fur" ? "" : "no-bg text-white"
                }`}
              >
               <span
                  style={{
                    color: selectedCategory === "fur" ? "white" : "blue",
                  }}
                >Fur</span>
              </button>
              <button
                onClick={() => setSelectedCategory("eyes")}
                className={`sc-button btn-feature-width  fl-button pri-3  mb-2 ${
                  selectedCategory === "eyes" ? "" : "no-bg text-white"
                }`}
              >
                 <span
                  style={{
                    color: selectedCategory === "eyes" ? "white" : "blue",
                  }}
                >Eyes</span>
              </button>
              <button
                onClick={() => setSelectedCategory("outfit")}
                className={`sc-button btn-feature-width  fl-button pri-3  mb-2 ${
                  selectedCategory === "outfit" ? "" : "no-bg text-white"
                }`}
              >
                 <span
                  style={{
                    color: selectedCategory === "outfit" ? "white" : "blue",
                  }}
                >Outfit</span>
              </button>
              <button
                onClick={() => setSelectedCategory("mouth")}
                className={`sc-button btn-feature-width  fl-button pri-3  mb-2 ${
                  selectedCategory === "mouth" ? "" : "no-bg text-white"
                }`}
              >
                <span
                  style={{
                    color: selectedCategory === "mouth" ? "white" : "blue",
                  }}
                >Mouth</span>
              </button>
              <button
                onClick={() => setSelectedCategory("headwear")}
                className={`sc-button btn-feature-width  fl-button pri-3  mb-2 ${
                  selectedCategory === "headwear" ? "" : "no-bg text-white"
                }`}
              >
                <span
                  style={{
                    color: selectedCategory === "headwear" ? "white" : "blue",
                  }}
                >Headwear</span>
              </button>
              <button
                onClick={() => setSelectedCategory("hand")}
                className={`sc-button btn-feature-width  fl-button pri-3  mb-2 ${
                  selectedCategory === "hand" ? "" : "no-bg text-white"
                }`}
              >
                <span
                  style={{
                    color: selectedCategory === "hand" ? "white" : "blue",
                  }}
                >Hand</span>
              </button>
            </div>
            <div className="col-xl-10 col-lg-10">
              {featureCollection
                .filter((catItem) => catItem.category === selectedCategory)
                .map((categoryItem) =>
                  categoryItem.itemList.map((item, index) => {
                    return <SliderItem item={item} />;
                  })
                )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
const SliderItem = (props) => (
  <div className="sc-author-box style-2">
    <div className="author-avatar">
      <img src={props.item.img} alt="Bossy Lion NFT" className="avatar" />
      {/* <div className="badge"></div> */}
    </div>
    <div className="author-infor">
      <h5 style={{ textTransform: "capitalize" }}>
        {props.item.trait}
      </h5>
      <span className="price">{props.item.occurrence}</span>
    </div>
  </div>
);

export default Featuers;
