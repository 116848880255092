import sanityClient from '@sanity/client'

export const client = sanityClient({
  projectId: 'zs67alnl',
  dataset: 'production',
  useCdn: true,
  token:
    'skb6Xl5bRU7ZSzYE0LtwPG98Ew0TvTkCXoHSjA67Q9Yaz1pCUsdAQE7yG478gJTgOBZkkOWp0Gsqx682j736N2tV90tCZZM8vWxnxtl9Z1Fl2v5NL1q342KjPXtUo4diIfVjQa2GTMnEgkicMc4yT8ADhhKHZcayow8oXW7Lwn1iIOshSySx', // or leave blank to be anonymous user
  apiVersion: '1', // or leave blank for latest stable version
  useCdn: false, // `false` if you want to ensure fresh data
})
